import React from 'react';
import i18n from '../../i18n';
import BlockVideo from '../../components/common/BlockVideo';
import Utils from '../../utils/utils';

const HomepageWhy = () => {
    const newHomePageBlock = [
        {
            id: 'globe',
            icon: 'icon-globe',
            title: i18n.t('home:renters-owners-title'),
            subtitle: i18n.t('home:renters-owners-subtitle'),
            classContentCard: true,
            iconHelp: false,
            numberPath: 15
        },
        {
            id: 'piggy-bank',
            icon: 'icon-piggy-bank',
            title: i18n.t('home:travel-save-title'),
            subtitle: i18n.t('home:travel-save-subtitle'),
            classContentCard: true,
            iconHelp: false,
            numberPath: 12
        },
        {
            id: 'thumb',
            icon: 'icon-thumb',
            title: i18n.t('home:foolproof-guarantees-title'),
            subtitle: i18n.t('home:foolproof-guarantees-subtitle'),
            classContentCard: true,
            iconHelp: false,
            numberPath: 8
        }
    ];
    const locales = ['fr', 'de', 'nl', 'en', 'it', 'es'];

    return (
        locales.includes(i18n.language) && (
            <BlockVideo
                cards={newHomePageBlock}
                title={i18n.t('home:thirtynine-seconds-title')}
                subtitle={i18n.t('home:thirtynine-seconds-subtitle')}
                poster={`${Utils.getCloudfrontDomain()}/images/website/home_page/thirtynine_seconds.png`}
                srcVideo={`${Utils.getCloudfrontDomain()}/images/website/home_page/MASTER_CONVICTION_${
                    i18n.language
                }.mp4`}
            />
        )
    );
};

export default HomepageWhy;
