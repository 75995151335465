import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../utils/utils';

export default function Card({ step, img, text }) {
    return (
        <div className="how-it-works-card">
            <div className="how-it-works-card-step">
                <span>{step}</span>
            </div>
            <div className="how-it-works-card-main">
                <img
                    className="how-it-works-card-img"
                    src={`${Utils.getCloudfrontDomain()}/images/website/home/${img}`}
                />
                <p className="how-it-works-card-info">{text}</p>
            </div>
        </div>
    );
}

Card.propTypes = {
    step: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};
