import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

export default function SponsorLink(props) {
    const [godfather, setGodfather] = useState();
    const defaultProfile = `${Utils.getCloudfrontDomain()}/images/website/icon_default_profile.jpg`;

    useEffect(() => {
        if (props.godfather) {
            setGodfather(props.godfather);
        }
    }, [props.godfather]);

    return godfather ? (
        <>
            <img
                className="sponsor-profile"
                src={godfather.image ? godfather.image.cdn_link : defaultProfile}
            />
            <div className="sponsor-link-main">
                <h1
                    className="sponsor-title"
                    dangerouslySetInnerHTML={{
                        __html: i18n.t('home:homepage.sponsor-link.title', {
                            firstname: godfather.first_name
                        })
                    }}
                />
                <a className="btn btn-primary btn-join-community btn-signup">
                    {i18n.t('home:homepage.sponsor-link.button')}
                </a>

                <div className="sponsor-about">
                    <hr className="sponsor-text-separator" />
                    <p
                        className="sponsor-text"
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('home:homepage.sponsor-link.text', {
                                guestpoint_link: i18n.t('url:blog_guestpoints')
                            })
                        }}
                    />
                </div>
            </div>
        </>
    ) : null;
}

SponsorLink.propTypes = {
    godfather: PropTypes.object.isRequired
};
