import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import { PROVIDER_JAWG } from '../../constants/search';

import PlaceAutocomplete from '../widget/PlaceAutocomplete';

export default class SearchHeadband extends React.PureComponent {
    static propTypes = {
        inHeader: PropTypes.bool
    };

    static defaultProps = {
        inHeader: false
    };

    constructor(props) {
        super(props);
        this.state = {
            place: '',
            submit: false
        };
        this.setLocation = this.setLocation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    setLocation(prediction) {
        if (prediction.gid) {
            this.setState(
                {
                    place: {
                        formatted_address: prediction.label,
                        location_id: prediction.gid
                    }
                },
                this.submit
            );
        } else {
            this.setState({ place: null });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.submit();
    }

    submit() {
        let url;
        const { place } = this.state;
        // format query string for search url
        const filters = this.buildFilters();
        if (filters) {
            url = `${i18n.t('search:search_url', {
                slug: encodeURIComponent(place.formatted_address)
            })}?${$.param(filters)}`;
        }
        // Everywhere search on empty search
        else {
            url = i18n.t('search:search_url', {
                slug: 'everywhere'
            });
        }

        this.redirectAndSubmit(url);
    }

    buildFilters() {
        const { place } = this.state;
        if (place.location_id) {
            return {
                location_id: place.location_id,
                provider: PROVIDER_JAWG
            };
        }
    }

    redirectAndSubmit(url) {
        // navigate to search page
        document.location.assign(url);
        // display submit loader
        this.setState({ submit: true });
    }

    render() {
        const submitClasses = classNames(
            'btn',
            'btn-primary',
            'btn-ajax',
            { sending: this.state.submit },
            { 'btn-lg': !this.props.inHeader }
        );
        return (
            <form className="search-headband form-inline" action="#" method="GET" onSubmit={this.onSubmit}>
                <PlaceAutocomplete
                    className="search-field"
                    placeholder={i18n.t('search:searchfield_placeholder')}
                    value={this.state.queryString}
                    onSelect={this.setLocation}
                    iconSearch
                />
                <button type="submit" className={submitClasses}>
                    {i18n.t('search:submit')}
                </button>
            </form>
        );
    }
}
