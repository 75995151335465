import React from 'react';
import Utils from '../../utils/utils';
import useMedia from '../../utils/useMedia';
import PropTypes from 'prop-types';

const HomepageCoverSlider = ({ countryIso }) => {
    const isMobile = useMedia('(max-width: 480px)');

    const getBackgroundImage = () => {
        if (countryIso === 'IN') {
            return 'images/website/migration/home/search-dashboard-in.webp';
        }

        return isMobile
            ? 'images/website/home_page/mobile-communication-search-background.jpg'
            : 'images/website/home_page/communication-search-background.jpg';
    };

    const containerStyle = {
        backgroundImage: `url(${Utils.getCloudfrontDomain()}/${getBackgroundImage()})`,
        backgroundSize: 'cover'
    };

    if (countryIso === 'IN') {
        containerStyle.backgroundPositionX = isMobile ? '-80%' : 'center';
    }

    return <div className="homepage-pictures" style={containerStyle} />;
};

HomepageCoverSlider.propTypes = {
    countryIso: PropTypes.string
};

HomepageCoverSlider.defaultProps = {
    countryIso: ''
};

export default HomepageCoverSlider;
