import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Api from '../../api/Api';
import Home from '../home/Home';
import Utils from '../../utils/utils';

export default function HomeNewest({ isLogged }) {
    const [homes, setHomes] = useState([]);
    const [error, setError] = useState(false);

    const fetchHomes = () => {
        Api.Home.getLastCompletedHomesWithCompletedUserProfile(4, Utils.getWebsiteLanguage())
            .done((response) => {
                setHomes(response);
                setError(!(response.length > 0));
            })
            .fail(() => {
                setError(true);
            });
    };

    useEffect(() => {
        fetchHomes();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="home-newest-main col-md-12">
                    <h2 className="home-newest-title home-page-block-title">
                        {i18n.t('dashboard:homepage-newest.title')}
                    </h2>
                    <p className="home-newest-subtitle home-page-block-text">
                        {i18n.t('dashboard:homepage-newest.subtitle')}
                    </p>
                    {(!error || homes.length > 0) && (
                        <div className="home-newest-homes-container">
                            <div className="home-newest-homes">
                                {homes.map((home) => (
                                    <Home
                                        key={home.id}
                                        home={home}
                                        displayFavoritePlaceholder={false}
                                        displayLocation={true}
                                        noSearch={true}
                                        isLogged={isLogged}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <a
                        className="home-newest-button btn btn-primary home-page-button"
                        href={i18n.t('search:search_url', { slug: 'everywhere' })}
                    >
                        {i18n.t('dashboard:homepage-newest.button')}
                    </a>
                </div>
            </div>
        </div>
    );
}

HomeNewest.propTypes = {
    isLogged: PropTypes.bool.isRequired
};
