import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import Card from './Card';
import usePrice from '../../../utils/usePrice';
import useGTM from '../../analytics/useGTM';
import { NAVIGATION_ACTION, NAVIGATION_AREAS, NAVIGATION_TEXT } from '../../analytics/analytics-constants';
import Analytics from '../../../utils/analytics';
import User from '../../../models/User';

function HowItWorks({ user }) {
    const product = usePrice();
    const cards = [
        {
            step: 1,
            img: 'how-it-works-sign-up.svg',
            text: i18n.t('dashboard:how-it-works.sign-up.title')
        },
        {
            step: 2,
            img: 'how-it-works-home.svg',
            text: i18n.t('dashboard:how-it-works.home.title')
        },
        {
            step: 3,
            img: 'how-it-works-membership.svg',
            text: i18n.t('dashboard:how-it-works.membership.title', {
                price: product.price,
                symbol: product.symbol
            })
        },
        {
            step: 4,
            img: 'how-it-works-exchange.svg',
            text: i18n.t('dashboard:how-it-works.exchange.title')
        }
    ];

    const onTrackNavigationButton = (text) => {
        const { fetchNavigation } = useGTM(user);
        Analytics.trackGTM(
            'button',
            fetchNavigation(NAVIGATION_ACTION.CLICK, NAVIGATION_AREAS.HOW_IT_WORKS, text)
        );
    };

    const handleSignUp = () => {
        onTrackNavigationButton(NAVIGATION_TEXT.SIGN_UP);
        document.dispatchEvent(new CustomEvent('open_signup_popup'));
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <header>
                        <h2 className="how-it-works-title home-page-block-title">
                            {i18n.t('dashboard:how-it-works.title')}
                        </h2>
                        <p
                            className="how-it-works-subtitle home-page-block-text"
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('dashboard:how-it-works.subtitle', {
                                    price: product.price,
                                    symbol: product.symbol
                                })
                            }}
                        />
                    </header>
                    <div className="how-it-works-container">
                        <div className="how-it-works-cards">
                            {cards.map((card) => (
                                <Card key={card.step} step={card.step} img={card.img} text={card.text} />
                            ))}
                        </div>
                    </div>
                    <footer className="how-it-works-footer">
                        <button
                            className="btn btn-primary how-it-works-button home-page-button"
                            onClick={handleSignUp}
                        >
                            {i18n.t('dashboard:how-it-works.button')}
                        </button>
                        <a
                            className="how-it-works-info"
                            href={i18n.t('url:subscription-he-cms')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {i18n.t('dashboard:how-it-works.info')}
                        </a>
                    </footer>
                </div>
            </div>
        </div>
    );
}

HowItWorks.propTypes = {
    user: PropTypes.instanceOf(User)
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(HowItWorks);
