import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import i18n from '../../i18n';
import Utils from '../../utils/utils';
import useMedia from '../../utils/useMedia';

const HomeGreenBlock = () => {
    const [subtitle, setSubtitle] = useState('');
    const [showSubtitle, setShowSubtitle] = useState(false);
    const cta = () => (
        <button
            className="btn btn-primary"
            onClick={() => window.open(i18n.t('home:b-corp.cta.url'), '_blank')}
        >
            {i18n.t('home:b-corp.cta')}
        </button>
    );
    const locale = Utils.getWebsiteLanguage();

    return (
        <div>
            <header className="container">
                <h2 className="home-green-block-title">{i18n.t('home:b-corp.title')}</h2>
                <p className="home-green-block-text">{i18n.t('home:b-corp.subtitle')}</p>
            </header>
            <div className="background-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="home-green-block-container">
                                <div className="home-green-block-part-left">
                                    <div className="home-green-block-type-animation">
                                        <TypeAnimation
                                            sequence={[
                                                i18n.t('home:b-corp.first-animation.title'),
                                                () => {
                                                    setSubtitle(
                                                        i18n.t('home:b-corp.first-animation.subtitle')
                                                    );
                                                    setShowSubtitle(true);
                                                },
                                                2000,
                                                () => {
                                                    setShowSubtitle(false);
                                                },
                                                i18n.t('home:b-corp.second-animation.title'),
                                                () => {
                                                    setSubtitle(
                                                        i18n.t('home:b-corp.second-animation.subtitle')
                                                    );
                                                    setShowSubtitle(true);
                                                },
                                                2000,
                                                () => {
                                                    setShowSubtitle(false);
                                                },
                                                i18n.t('home:b-corp.third-animation.title'),
                                                () => {
                                                    setSubtitle(
                                                        i18n.t('home:b-corp.third-animation.subtitle')
                                                    );
                                                    setShowSubtitle(true);
                                                },
                                                2000,
                                                () => {
                                                    setShowSubtitle(false);
                                                }
                                            ]}
                                            speed={30}
                                            style={{ fontSize: '52px' }}
                                            wrapper="p"
                                            repeat={Infinity}
                                        />
                                    </div>
                                    <p
                                        className="home-green-block-subtitle"
                                        style={{
                                            transition: 'opacity 0.5s',
                                            opacity: showSubtitle ? '1' : '0'
                                        }}
                                    >
                                        {subtitle}
                                    </p>
                                    {useMedia('(min-width: 992px)') && cta()}
                                </div>
                                <div className="home-green-block-part-right">
                                    <img
                                        className="home-green-block-img"
                                        src={`${Utils.getCloudfrontDomain()}/images/website/home_page/${locale}_bcorp_illustration_${
                                            useMedia('(max-width: 1199px)') ? 'mobile' : 'desktop'
                                        }.svg`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {useMedia('(max-width: 991px)') && cta()}
                            <footer className="home-green-block-footer">
                                <img
                                    className="home-green-block-logo"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/home_page/${locale}_bcorp_logo.svg`}
                                />
                                <p
                                    className="home-green-block-info"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t('home:b-corp-info-band')
                                    }}
                                />
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeGreenBlock;
