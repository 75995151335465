import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CardInfo from '../card-info/CardInfo';
import i18n from '../../i18n';
import useGTM from '../analytics/useGTM';
import { NAVIGATION_ACTION, NAVIGATION_AREAS, NAVIGATION_TEXT } from '../analytics/analytics-constants';
import Analytics from '../../utils/analytics';
import User from '../../models/User';

const TV_LINKS = {
    es: 'blog/publicidad-tv-homeexchange-los-fernandez-2023',
    fr: 'blog/pub-tv-homeexchange-les-lambert-2023'
};

const BlockVideo = ({ user, cards, title, subtitle, poster, srcVideo }) => {
    const onTrackNavigationButton = (text) => {
        const { fetchNavigation } = useGTM(user);
        Analytics.trackGTM('button', fetchNavigation(NAVIGATION_ACTION.CLICK, NAVIGATION_AREAS.VIDEO, text));
    };

    const handleOnClick = () => {
        onTrackNavigationButton(NAVIGATION_TEXT.SIGN_UP);
        document.dispatchEvent(new CustomEvent('open_signup_popup'));
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <header>
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </header>
                    <div className="container-block-video">
                        <div className="container-card-info">
                            {cards.map((item) => (
                                <CardInfo
                                    key={item.id}
                                    text={item.title}
                                    subtext={item.subtitle}
                                    icon={item.icon}
                                    classContentCard={item.classContentCard}
                                    iconHelp={item.iconHelp}
                                    numberPath={item.numberPath}
                                />
                            ))}
                        </div>
                        <div className="info-cards-video-container">
                            <video className="info-cards-video" poster={poster} controls>
                                <source src={srcVideo} type="video/mp4" />
                            </video>
                            {Object.keys(TV_LINKS).includes(i18n.language) && (
                                <div className="container-link">
                                    <i className="icon-tv-link" />
                                    <a href={TV_LINKS[i18n.language]}>{i18n.t('home:link-tv')}</a>
                                </div>
                            )}
                        </div>
                    </div>
                    <footer>
                        <button className="btn btn-primary home-page-button" onClick={handleOnClick}>
                            {i18n.t('dashboard:how-it-works.button')}
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    );
};

BlockVideo.propTypes = {
    user: PropTypes.instanceOf(User),
    cards: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    poster: PropTypes.string,
    srcVideo: PropTypes.string
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(BlockVideo);
