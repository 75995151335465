import React from 'react';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

const HomepageWhy = () => {
    const homePageBlock = [
        {
            id: 'globe',
            srcImg: `${Utils.getCloudfrontDomain()}/images/website/home/globe.svg`,
            altImg: 'home:homepage.why.title1',
            title: 'home:homepage.homeexchange_why.item1.title',
            text: 'home:homepage.homeexchange_why.item1.content'
        },
        {
            id: 'piggy-bank',
            srcImg: `${Utils.getCloudfrontDomain()}/images/website/home/piggy-bank.svg`,
            altImg: 'home:homepage.why.title2',
            title: 'home:homepage.homeexchange_why.item2.title',
            text: 'home:homepage.homeexchange_why.item2.content'
        },
        {
            id: 'thumb',
            srcImg: `${Utils.getCloudfrontDomain()}/images/website/common/zen-blue.svg`,
            altImg: 'home:homepage.why.title3',
            title: 'home:homepage.homeexchange_why.item3.title',
            text: 'home:homepage.homeexchange_why.item3.content'
        }
    ];
    return (
        <div className="container old-home-why">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="home-page-block-title">
                        {i18n.t('home:homepage.homeexchange_why.title')}
                    </h2>
                    <div id="home-why-details" className="row details">
                        {homePageBlock.map((item) => (
                            <div className="col-md-4" key={item.id}>
                                <img id={item.id} src={item.srcImg} alt={i18n.t(item.altImg)} />
                                <h3 className="home-page-block-small-title">{i18n.t(item.title)}</h3>
                                <p className="home-page-block-text">{i18n.t(item.text)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomepageWhy;
